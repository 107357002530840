@import 'assets/styles/variables.scss';

.mainInfoBlock {
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: 20px;
  margin-bottom: 20px;
  .generalDetails {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 345px);
    flex: 1;
    .detailsBlock {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 15px;
      margin-bottom: 15px;
    }
  }
  .rightColumn {
    width: 345px;
    .editors {
      &__info {
        font-size: 10px;
        font-style: italic;
        margin: 5px 0;
        padding: 0 10px;
      }

      &__logs {
        text-align: end;
      }

      &__see_logs {
        color: $main_color !important;
        font-style: italic;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
}
.detailsItem {
  display: block;
  width: 49%;
  label,
  span {
    font-style: normal;
    font-size: 14px;
    color: #000000;
    font-family: $monserrat;
  }
  & > label {
    font-weight: bold;
    white-space: normal;
    word-wrap: break-word;
    max-width: 40%;
  }
  & > span {
    margin-left: 10px;
    white-space: normal;
    word-break: break-all;
  }
}
.descriptionBlock {
  width: 100%;
}

.tableContainer {
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  table {
    border-collapse: initial;
  }
  th {
    white-space: nowrap;
    border-right: none !important;
    &:not(:first-child) {
      border-left: 1px solid #dcdcdc;
    }
    &:has(span) {
      padding: 0;
      text-align: center;
    }
  }
  td {
    -webkit-text-size-adjust: 100%;
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    &:not(:first-child) {
      border-left: 1px solid #dcdcdc;
    }
    &:nth-child(3),
    &:nth-child(4) {
      white-space: normal;
      word-break: break-word;
    }
    &.merged {
      background-color: white !important;
      vertical-align: top;
      padding: 9px 16px 8px;
    }

    &.checkboxCell {
      min-width: 50px;
      text-overflow: clip;
      width: 50px;
      padding: 0;
      text-align: center;
    }
    &.locationCell {
      width: 15%;
      min-width: 150px;
      white-space: normal;
      word-break: break-all;
    }
    &.sublocationCell {
      white-space: normal;
      word-break: break-all;
      width: 15%;
      min-width: 130px;
    }
    &.containedInCell {
      white-space: normal;
      word-break: break-all;
      width: 10%;
      min-width: 130px;
    }
    &.noteCell {
      white-space: normal;
      word-break: break-all;
      width: 15%;
      min-width: 155px;
    }
    &.statusCell {
      white-space: normal;
      word-break: break-all;
      width: 10%;
      min-width: 140px;
    }
    &.minQtyCell {
      width: 11%;
      min-width: 90px;
    }
    &.byAheadAccountCell {
      width: 11%;
      min-width: 80px;
    }
    &.currentQtyCell {
      width: 13%;
      min-width: 130px;

      .qtyContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &.warning {
        background-color: #ffefd0 !important;
      }
    }
    &.unitsCostsCell {
      min-width: 135px;
      width: 135px;
    }
    &.locationQtyCell {
      width: 135px;
      min-width: 135px;
    }
  }

  .emptyTable {
    background-color: #f7f7f7;
    padding: 25px 0;
    font-size: 18px;
    text-align: center;
  }

  .footnote {
    font-size: 9px;
    position: relative;
    top: -8px;
  }
}

button.approveButton {
  height: 30px;
  margin-left: 25px;
  width: 175px;
}

@media screen and (max-width: $breakpoint-1180) {
  .mainInfoBlock {
    flex-direction: column-reverse;
    .generalDetails {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      flex: 1;
      & > h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .rightColumn {
      margin-bottom: 15px;
      width: 100%;
    }
  }

  .detailsItem {
    width: 100%;
  }

  .tableContainer {
    position: relative;
    left: -20px;
    width: 100vw !important;
  }

  button.approveButton {
    margin-left: 0;
    width: 100%;
  }
}
