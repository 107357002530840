@import 'assets/styles/variables.scss';

.details {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 10px;

  &__block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 100px;
    row-gap: 20px;

    @media screen and (max-width: $breakpoint-1180) {
      grid-template-columns: 1fr;
    }

    &.onlyChild {
      grid-template-columns: 1fr;
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;

    & > label {
      font-weight: bold;
      white-space: nowrap;
      margin: 0;
      font-size: 14px;
      font-family: $monserrat;
      color: #000000;
      font-style: normal;
    }
  }
}

.title {
  font-family: $monserrat;
  color: black;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  display: flex;
  align-items: center;
}
