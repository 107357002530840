@import 'assets/styles/variables.scss';

.verification {
  button.verification__btn {
    width: auto;
    height: 30px;

    @media screen and (max-width: $breakpoint-1180) {
      width: 100%;
    }
  }

  &__menu {
    width: 200px;
  }

  .opened {
    transform: rotate(180deg);
  }
}
