@import 'assets/styles/variables.scss';

div.trigger {
  display: flex;
  line-height: 14px;
  margin-left: 5px;

  svg {
    width: 14px;
    height: 14px;
    color: #000;
    outline: none;
  }
}

div.tooltip {
  font-family: $monserrat;
  background: $main_color;
  padding: 5px 15px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  max-width: 280px;
  & > span {
    color: white;
    font-size: 12px;
    white-space: normal;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  div.tooltip {
    max-width: 200px;
  }
}
