@import 'assets/styles/variables.scss';

.buttonSection {
  display: flex;

  .approveButton,
  .statusButton,
  .requestForApprovalButton,
  .reopen {
    font-family: $monserrat !important;
    width: 200px;
    height: 30px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  .approveButton,
  .reopen {
    background: #f6f6f6 !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    color: black !important;
    &:hover {
      background: #ededed !important;
    }
  }

  .statusButton {
    background: $extra_color !important;
    color: black !important;
    &:hover {
      background: #f2cb33 !important;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      background-color: #e6e6e6 !important;
    }
  }

  .requestForApprovalButton {
    background: $main_color !important;
    color: white !important;
    &:hover {
      background: #45697a !important;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .buttonSection {
    display: flex;
    flex-direction: column-reverse;
    button {
      margin-bottom: 15px !important;
    }
    .statusButton,
    .requestForApprovalButton,
    .approveButton,
    .reopen {
      width: 100%;
      height: 40px;
    }
  }
}
