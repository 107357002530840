@import 'assets/styles/variables.scss';

.tableControls {
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: auto;

  @media screen and (max-width: $breakpoint-1180) {
    width: stretch;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  text-align: center;
  width: 400px;
  text-wrap-style: balance;

  &__title,
  &__subtitle {
    font-family: $monserrat;
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
  }

  &__subtitle {
    font-size: 18px;
  }
}

table.table {
  overflow-x: hidden;

  @media screen and (max-width: $breakpoint-1180) {
    overflow-x: auto;
  }

  th {
    white-space: nowrap;

    &:last-child {
      width: 50px;
      min-width: 50px;
    }
  }

  tr.loose_item_table_row {
    & > td {
      max-width: 0;
      overflow: hidden;
      height: 40px;
      text-overflow: ellipsis;
      -webkit-text-size-adjust: 100%;

      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }

      &.looseItemCell {
        width: 30%;
        min-width: 250px;
        background-color: white !important;
        vertical-align: top;
      }
      &.customCell {
        min-width: 200px;
        background-color: white !important;
        vertical-align: top;
      }
      &.locationCell {
        width: 15%;
        min-width: 200px;
      }
      &.sublocationCell {
        width: 15%;
        min-width: 170px;
      }
      &.containedInAssetCell {
        width: 10%;
        min-width: 170px;
      }
      &.unitCostsCell {
        min-width: 135px;
      }
      &.qtyCell {
        width: 10%;
        min-width: 125px;

        &.warning {
          background-color: #ffefd0 !important;
        }

        .displayQtyContent {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      &.baaCell {
        width: 10%;
        min-width: 125px;
      }
      &.statusCell {
        width: 10%;
        min-width: 165px;
        white-space: normal;
        word-break: break-word;
      }
      &.checkboxCell {
        width: 50px;
        min-width: 50px;
        padding: 0;
        text-align: center;
      }
    }
  }
}
