@import 'assets/styles/variables.scss';

.tabs {
  margin-bottom: 25px;
  div.tabs__bar {
    margin-bottom: 0;
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .tabs {
    position: relative;
    left: -20px;
    width: 100vw;
    div.tabs__bar {
      border-radius: 0 0 0 0 !important;
      width: 100%;
      overflow: auto;
      margin-bottom: 0;

      & > button {
        min-width: 120px;
        height: 60px;
        width: 50%;
        font-size: 14px;
        padding: 5px 16px;
        white-space: normal;
        line-height: 1.25;
        &:first-child {
          border-radius: 0 0 0 0;
        }
        &:last-child {
          border-radius: 0 0 0 0;
        }
      }
    }
  }
}
