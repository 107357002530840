@import 'assets/styles/variables.scss';

button.clear {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  color: $main_color;
  display: flex;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-left: 3px;
  }
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }

  &.increased {
    margin-bottom: 0;
    svg {
      width: 25px;
      height: 25px;
    }
  }
}
