$min-head-cell-width: 80px;

th.initialCell {
  min-width: 300px;
  width: 300px;
  position: sticky;
  left: 0;
  z-index: 7;
  padding: 0;

  &.month {
    top: 0;
  }
  &.day {
    top: 40px;
  }
  &.note {
    top: 65px;
  }
}

.monthCell {
  height: 40px;
  padding: 5px 16px !important;
  min-width: $min-head-cell-width;
  position: sticky;
  z-index: 3;
  top: 0;
  .monthBlock {
    display: flex;
    justify-content: flex-start;
    & > span {
      left: 316px;
      position: sticky;
      text-transform: uppercase;
    }
  }
}

.dayCell {
  padding: 0 !important;
  min-width: $min-head-cell-width;
  position: sticky;
  z-index: 3;
  top: 40px;
  .dayBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.noteCell {
  height: 40px;
  padding: 0 !important;
  position: sticky;
  z-index: 3;
  top: 65px;
}

.displayMode,
.editMode {
  width: $min-head-cell-width;
  font-size: 11px;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  line-height: 11px;
  box-sizing: border-box;
}

.displayMode {
  white-space: normal;
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  height: 100%;
}

.editMode {
  padding: 4px;
  textarea {
    line-height: 11px !important;
    font-size: 11px !important;
    height: 50px !important;
    padding: 1px !important;
  }
}
