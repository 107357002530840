.banner {
  position: fixed;
  opacity: 0;
  bottom: -50px;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 15;
  transition: all 0.2s ease-in-out;
  width: max-content;

  &.visible {
    bottom: 20px;
    opacity: 1;
    pointer-events: auto;
  }

  &__details {
    display: flex;
    gap: 20px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__label {
    font-size: 12px;
    color: #888;
  }

  &__value {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  &__button {
    display: flex;
    & > button {
      margin-bottom: 0;
    }
  }
}
