@import 'assets/styles/variables.scss';

.table_row {
  & > td {
    max-width: 0;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;
    -webkit-text-size-adjust: 100%;

    &:not(:last-child) {
      border-right: 1px solid #dcdcdc;
    }

    &.invalid {
      background-color: $table_error_cell_background !important;
    }
    &.warning {
      background-color: #ffefd0 !important;
    }

    &.checkboxCell {
      min-width: 50px;
      text-overflow: clip;
      width: 50px;
    }

    &.looseItemCell {
      min-width: 200px;
      width: 15%;
    }

    &.looseItemLocationCell {
      min-width: 310px;
      padding: 0 !important;
      width: 20%;
    }

    &.availableQtyCell {
      min-width: 200px;
      width: 5%;
    }

    &.destinationLocationCell {
      min-width: 200px;
      width: 15%;
      padding: 0 !important;
    }

    &.destinationSublocationCell {
      min-width: 200px;
      width: 15%;
      padding: 0 !important;
    }

    &.destinationAssetCell {
      min-width: 200px;
      width: 10%;
      padding: 0 !important;
    }

    &.moveQtyCell {
      width: 10%;
      min-width: 150px;
      padding: 0 16px;
    }

    &.moveBaaCell {
      width: 10%;
      min-width: 150px;
      padding: 0 16px;
    }

    &.removeCell {
      width: 60px;
      min-width: 60px;
      padding: 0;

      button.removeCell_btn {
        display: block;
        width: 35px;
        height: 35px;
        margin: 0 auto;
      }
    }
  }
}

.errorContainer {
  padding: 0 16px 8px 16px;
}

.select_input_style {
  & > div {
    max-width: 100%;
    width: 100%;
    & > div {
      max-width: 100%;
      width: 100%;
      background-color: transparent;
    }
  }
}

.quantity_input_style {
  width: 100%;
  & > div {
    background-color: transparent !important;
    & > div {
      & > input {
        padding: 0 !important;
      }
    }
  }
}

.asset_input_style {
  width: 100%;
  & > div {
    & > div {
      & > div {
        background-color: transparent !important;
        padding-right: 16px !important;
      }
    }
  }
}
