@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 30px 60px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  .formWrapper {
    margin-bottom: 30px;
  }
  .tableContainer {
    border-radius: 8px;
    margin-bottom: 20px;

    table {
      border-collapse: initial;
    }
    td {
      -webkit-text-size-adjust: 100%;
      &:not(:last-child) {
        border-right: 1px solid #dcdcdc;
      }
    }

    .emptyTable {
      background-color: #f7f7f7;
      padding: 25px 0;
      font-size: 18px;
      text-align: center;
    }

    .rowName,
    .rowClearName {
      text-decoration: underline;
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
    }

    .rowClearName {
      text-decoration: none !important;
      cursor: default;
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .pageContainer {
    font-family: $monserrat;
    padding: 60px 20px 20px;

    .formWrapper {
      .headerBlock {
        border-bottom: none;
        position: fixed;
        height: 60px;
        padding: 10px 20px 0;
        top: 0;
        left: 0;
        background: #fff;
        width: 100vw;
        box-sizing: border-box;
        z-index: 1;
        margin: 0;

        button {
          background: #f6f6f6;
          border-radius: 8px;
          height: 30px;
          width: 30px;
          justify-content: center;
        }

        &__controls {
          position: fixed;
          right: 20px;
          top: 20px;

          svg {
            margin-right: 0;
          }
        }
      }
      .mainInfoBlock {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 50px;
        .generalDetails {
          max-width: 450px;
          width: 100%;
          .formRow {
            width: 100%;
            margin-bottom: 10px;
            justify-content: normal;
            .inputWrapper {
              width: 180px;
              font-size: 12px;
            }
            & > label {
              width: 200px;
            }
          }
        }
        .rightColumn {
          max-width: 450px;
        }
      }
      .descriptionBlock {
        flex-direction: column;
        label {
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }

    .tableContainer {
      position: relative;
      left: -20px;
      width: 100vw;
      .firstRow {
        min-width: 260px;
        box-sizing: border-box;
      }
      .lastRow {
        width: 100px !important;
        min-width: 100px !important;
        box-sizing: border-box;
      }
    }
  }
}
