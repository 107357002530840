@import 'assets/styles/variables.scss';

.dialog {
  width: 930px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  font-family: $monserrat;
  box-sizing: border-box;

  .form {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;
    &__block {
      display: flex;
      flex-direction: column;
      flex-basis: 47%;
      row-gap: 20px;
      &_cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;

        &.checkbox {
          width: 290px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .dialog {
    width: 100%;
    min-height: 100%;
    height: auto;
    overflow-y: auto;
    padding: 70px 20px 20px;

    .form {
      flex-wrap: wrap;
      row-gap: 20px;
      &__block {
        flex-basis: 100%;
        &_cell {
          width: 100%;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          height: auto;
          row-gap: 10px;

          &.checkbox {
            width: 100%;
            justify-content: flex-end;
            flex-direction: row;
            align-items: center;
            column-gap: 20px;
          }
        }
      }
    }
  }
}
