.cell {
  box-sizing: border-box !important;
  white-space: nowrap;

  &.wrapText {
    white-space: normal;
  }
}

.footnote {
  font-size: 9px;
  position: relative;
  top: -8px;
}
