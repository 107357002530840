@import 'assets/styles/variables.scss';

.footer {
  margin-top: auto;
  @media screen and (max-width: $breakpoint-1180) {
    width: 100%;

    & > button {
      width: 100%;
    }
  }
}
