@import 'assets/styles/variables.scss';

.wrapper {
  width: 900px;
  box-sizing: border-box;
  display: flex;
  column-gap: 60px;

  &.narrowed {
    width: 410px;
    & > div {
      flex-basis: 100%;
    }
  }

  .block {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    row-gap: 20px;
    &__cell {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;
      &_label {
        font-family: $monserrat;
        margin: 0;
        font-size: 14px;
      }
      &_input {
        position: relative;
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-1180) {
  .wrapper {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;

    &.narrowed {
      width: 100%;
    }

    .block {
      flex-direction: column;
      row-gap: 0;
      &:first-child {
        margin-bottom: 20px;
      }
      &__cell {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &_label {
          margin: 0 0 10px 0;
        }
        &_input {
          width: 100%;
        }
      }
    }
  }
}
