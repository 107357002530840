@import 'assets/styles/variables.scss';

.pageContainer {
  font-family: $monserrat;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  min-height: 100%;
  margin: 0 auto;
  box-sizing: border-box;

  .pageHeader {
    h1 {
      font-weight: 700;
      font-size: 24px;
      margin: 0 0 10px;
    }
  }

  .entityTitle {
    margin-top: 30px;
  }

  .mainInfoBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-top: 15px;

    p {
      margin: 0 0 5px;
      font-size: 14px;
    }

    .templateCell {
      margin-bottom: 10px;
      cursor: default;
    }

    .templatesButtons {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 10px;
    }

    .addComponentButton {
      font-family: $monserrat;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      padding: 6px 15px;
      color: #fff;
      border-radius: 12px;
      height: 36px;
      box-sizing: border-box;
      background: $main_color;
      white-space: nowrap;

      span {
        display: flex;
        align-items: center;
        text-transform: capitalize;
      }

      svg,
      img {
        width: 22px;
        height: 22px;
        margin-right: 14px;
      }
    }

    .controlButtons {
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;

      button {
        margin: 0 0 0 20px;
      }
    }
  }

  .chipsBlock {
    flex-grow: 1;
    margin: 40px 0 -15px;
    padding: 0 0 5px 0;
    box-sizing: border-box;
    max-width: 700px;

    .fieldsChip {
      font-family: $monserrat;
      background-color: #7aa5ba;
      border-radius: 8px;
      color: white;
      margin-right: 10px;
      margin-bottom: 10px;

      & > span {
        padding: 6px 16px;
      }

      & > svg {
        color: #fff;
        height: 16px;
      }
    }
  }

  .rowWrapper {
    display: flex;
    margin: 35px 0 15px;

    label {
      min-width: 150px;
      max-width: 150px;
      word-break: break-word;
      padding-right: 5px;
      box-sizing: border-box;
    }

    p {
      font-weight: 700;
      margin: 0;
    }
  }

  &.precondition {
    .textInput {
      background: #f7f7f7;
      border-radius: 8px;
      width: 100%;
      margin: 0;
      border: 1px solid transparent;

      & > div {
        & > div {
          padding: 10px 10px 25px !important;
        }
      }

      &.error {
        border-color: $error_color;
      }

      fieldset {
        border: none;
      }
      input,
      textarea {
        font-family: $monserrat;
        font-weight: 400;
        padding: 0 0 8px;
        font-size: 14px;
        line-height: 14px;
        width: 100%;
        color: #000;

        &::placeholder {
          opacity: 1;
          color: #ccc;
        }
      }
      input {
        padding: 8px 16px;
      }
    }

    .errorHint {
      color: $error_color;
      font-weight: 400;
      font-size: 10px;
      padding: 3px 0 0 5px;
      margin-bottom: -16px;
    }

    .formCell,
    .checkboxCell {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      margin-top: 30px;
      width: 427px;

      label {
        margin: 0;
        font-size: 14px;
      }

      .dataLabel {
        width: 240px;
        line-height: 33px;
      }

      .inputWrapper {
        position: relative;

        & > span,
        & > p {
          position: absolute;
          font-weight: 400;
          font-size: 10px;
          left: 5px;
          bottom: -14px;
          color: $error_color;
        }

        & > p {
          margin: 0 0 -5px;
          color: #000;
        }
      }

      &.reducedBottomMargin {
        margin-bottom: 12px;
      }
    }

    .checkboxCell {
      width: 320px;
    }
  }
}
